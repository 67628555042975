import React, { useContext } from 'react'
import { Flex, Input, Text, Box } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { useAuth } from '../hooks/useAuth'
import eventTracking from '../services/eventTracking'

const PublicLeaderboardCover = ({ setRFID }) => {
	const { profile } = useAuth()

	const resolver = yupResolver(
		object()
			.shape({
				cardId: string().trim().required().min(4)
			})
			.required()
	)

	const { register, handleSubmit, getValues, setFocus } = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})

	const onSubmit = () => {
		setRFID(getValues('cardId'))
		eventTracking('use_invenue_tablet', {
			user_id: profile?.id
		})
	}

	React.useEffect(() => {
		setFocus('cardId', { shouldSelect: true })
	}, [])

	return (
		<form
			id="form"
			name="form"
			onSubmit={handleSubmit(onSubmit)}
			style={{ height: '100vh' }}
		>
			{/* Capture full touch for input focus */}
			<Box
				opacity={0}
				position={'fixed'}
				top={0}
				left={0}
				right={0}
				bottom={0}
				zIndex={100}
				onClick={() => setFocus('cardId', { shouldSelect: true })}
			/>
			<Flex flex="1" mt="7vh" direction="column" alignItems="center">
				<Flex position="relative" w="38.86vw">
					<StaticImage src="../images/logo-pl.png" alt="logo" />
				</Flex>
				<Text as="h1" variant="titlePL">
					SCAN YOUR
					<br />
					CARD
					<br />
					BELOW FOR <br />
					LEADERBOARD
				</Text>
				<Flex position="relative" w="67vw" h="34vh">
					<StaticImage
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							animationName: 'bounce',
							animationDuration: '8s',
							animationIterationCount: 'infinite'
						}}
						alt="Arrow"
						src="../images/arrow.png"
					/>
				</Flex>
				<Input
					id="cardId"
					type="text"
					opacity={0}
					color="#fff"
					pointerEvents="none"
					position="fixed"
					top="0"
					left="0"
					{...register('cardId')}
				/>
				<StaticImage
					objectFit="contain"
					style={{
						position: 'fixed',
						width: '27vw',
						// height: 937, 12.3
						left: 0,
						bottom: 0
					}}
					alt="Duck"
					src="../images/duck-public.png"
				/>
			</Flex>
		</form>
	)
}

export default PublicLeaderboardCover
