import * as React from 'react'
import { useContext, useState } from 'react'
import { Container, Flex } from '@chakra-ui/react'
import { WindowHeightContext } from '../context/windowHeightContext'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layout'

import PublicLeaderboardPage from '../pagesComponents/public-leaderboard'
import PublicLeaderboardCover from '../pagesComponents/public-leaderboard-cover'

const PublicLeaderboard = ({ location }) => {
	const params = new URLSearchParams(location.search)
	const cardId = params.get('cardId')?.toString() || null
	const height = useContext(WindowHeightContext)
	const [rfid, setRFID] = useState(cardId)
	const gData = useStaticQuery(FetchAllQuery)
	const games = gData?.allMarkdownRemark?.edges || []

	return (
		<Layout title="In-Venue Leaderboard">
			<Container
				variant="dark"
				// actualheight={height}
				position="relative"
				display="flex"
				flexDirection="column"
				// height={height}
				w="full"
				// maxW="2296px !important"
				p="0"
			>
				<Flex
					flex="1"
					direction="column"
					_focus={{
						outline: 'none',
						boxShadow: 'none'
					}}
				>
					{!!rfid ? (
						<PublicLeaderboardPage
							games={games}
							rfid={rfid}
							setRFID={setRFID}
						/>
					) : (
						<PublicLeaderboardCover setRFID={setRFID} />
					)}
				</Flex>
			</Container>
		</Layout>
	)
}

export default PublicLeaderboard

const FetchAllQuery = graphql`
	query {
		allMarkdownRemark(
			sort: { order: ASC, fields: frontmatter___id }
			filter: { frontmatter: { title: { ne: "" } } }
		) {
			edges {
				node {
					frontmatter {
						id
						title
						image {
							childImageSharp {
								gatsbyImageData
							}
						}
					}
				}
			}
		}
	}
`
