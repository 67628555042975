const eventTracking = (name, data = {}) => {
	const dataObj = {}
	Object.keys(data || {}).forEach((key) => {
		if (!!data[key] && typeof data[key] !== 'undefined')
			dataObj[key] = data[key]
	})

	typeof window !== 'undefined' &&
		window.gtag &&
		window.gtag('event', name, dataObj)
}

export default eventTracking
