import * as React from 'react'
import { Flex, Spinner as SpinnerChakra } from '@chakra-ui/react'

const Spinner = (props) => {
	return (
		<Flex my="40px" alignItems="center" justifyContent="center" {...props}>
			<SpinnerChakra
				thickness="4px"
				speed="0.7s"
				emptyColor="gray"
				color="gold"
				size="xl"
			/>
		</Flex>
	)
}

export default Spinner
